import {pinJSONToIPFS} from './pinata.js'

require('dotenv').config();
const alchemyKey = "https://eth-mainnet.alchemyapi.io/v2/KnaV3NZXrtLG_BcGNV-pP9Vh3lhKB3q5";
//const alchemyKey = "https://eth-rinkeby.alchemyapi.io/v2/KavBLl1TaMYYmb7ce0umeMVRFfIdrxrG";
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);
const BN = require('bn.js');

const contractABI = require('../contract-abi.json')
const contractAddress = "0x2436313e3f84727009df86DF63a6706FB0c252DD";

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const address = await window.ethereum.enable();
      const obj = {
        connectedStatus:true,
        status:"",
        address:address
      }
      return obj;
    } catch (error) {
      return {
        connectedStatus: false,
        status: "🦊 Connect to Metamask using the button"
      }
    }
  } else {
    return {
      connectedStatus: false,
      status: "🦊 You must install Metamask for your browser: https://metamask.io/download.html"
    }
  }
};

export const mintNFT = async (quantity) => {
  const tokenPrice = web3.utils.toWei("0.02");
  const total = tokenPrice * quantity;

  //load smart contract
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);//loadContract();
  console.log(total);

  //set up your Ethereum transaction
  const transactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: window.ethereum.selectedAddress, // must match user's active address.
    value: parseInt(total).toString(16),
    'data': window.contract.methods.buy(quantity).encodeABI() //make call to NFT smart contract 
  };
  
  //sign transaction via Metamask
  try {
    console.log(transactionParameters);
    const txHash = await window.ethereum
      .request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      });
      return {
        success: true,
        status: "✅ Check out your transaction on Etherscan: https://etherscan.io/tx/" + txHash
      }
  } catch (error) {
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message
    }
  }
}