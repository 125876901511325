import { useEffect, useState } from "react";

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { connectWallet, mintNFT } from './utils/interact.js'

window.amount = 1;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  size: {
    height: 30,
    width: 110
  }
}));

const Minter = (props) => {

  //State variables
  const [isConnected, setConnectedStatus] = useState(false);
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");

  const connectWalletPressed = async () => { //TODO: implement
    const walletResponse = await connectWallet();
    setConnectedStatus(walletResponse.connectedStatus)
    setStatus(walletResponse.status)
    if (isConnected) {
      setWallet(walletAddress)
    }
  };

  const onMintPressed = async () => { //TODO: implement
    const { status } = await mintNFT(window.amount);
    setStatus(status);
  };

  useEffect(async () => {
    if (window.ethereum) { //if Metamask installed
      try {
        const accounts = await window.ethereum.request({ method: "eth_accounts" }) //get Metamask wallet
        if (accounts.length) { //if a Metamask account is connected
          setConnectedStatus(true);
          setWallet(accounts[0]);
        } else {
          setConnectedStatus(false);
          setStatus("🦊 Connect to Metamask using the button above.");
        }
      } catch {
        setConnectedStatus(false);
        setStatus(
          "🦊 Connect to Metamask using the button above." +
          walletAddress
        );
      }
    }
  });

  const classes = useStyles();

  return (
    <>
      <AppBar position="static">
        {/* <Toolbar>
          <Typography variant="h6" noWrap>
            Paper People
          </Typography>
        </Toolbar> */}
      </AppBar>

      <div className="Minter">

        <button id="walletButton" onClick={connectWalletPressed}>
          {isConnected ? (
            "👛 Connected: " +
            String(walletAddress).substring(0, 6) +
            "..." +
            String(walletAddress).substring(38)
          ) : (
            <span>👛 Connect Wallet</span>
          )}
        </button>

        <h1>Number to Mint</h1>
        <form>
          <input
            type="number"
            defaultValue="1"
            min="1"
            max="10"
            onChange={(event) => window.amount = event.target.value}
          />
        </form>

        <button id="mintButton" onClick={onMintPressed}>
          Mint
        </button>

        <p id="status">
          {status}
        </p>
      </div>
    </>
  );
};

export default Minter;